import { UserManager } from "oidc-client";
//--
import * as Constants from "../../types/constants/oidc";
import LocalStorageWrapper from "../../core/wrappers/browserStorage/localStorage-wrapper";
import MerchantQueryService from "../../services/query/MerchantQueryService";

interface IUserManager {
  events: any;
  getUser: () => any;
  signinRedirect: () => void;
  signinRedirectCallback: () => void;
  signinSilent: () => any;
  signoutRedirect: () => void;
  signoutRedirectCallback: () => void;
}
export default class AuthService {
  private _userManager: IUserManager;
  private _accessToken: any;

  constructor() {
    const stsSettings = {
      authority: Constants.StsAuthority,
      client_id: Constants.ClientId,
      client_secret: Constants.ClientSecret,
      scope: Constants.Scope,
      response_mode: Constants.ResponseMode,
      response_type: Constants.ResponseType,
      redirect_uri: Constants.RedirectUri,
      post_logout_redirect_uri: Constants.LogoutRedirectUri,
      userStore: Constants.UserStore,
      loadUserInfo: false,
    };

    this._userManager = new UserManager(stsSettings);

    this._userManager.events.addUserLoaded((user: any) => {
      this._accessToken = user.access_token;
      localStorage.setItem("access_token", user.access_token);
      localStorage.setItem("id_token", user.id_token);


    });
    this._userManager.events.addSilentRenewError((e: any) => {
      console.log("silent renew error", e.message);
    });
    this._userManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.signinSilent();
    });
  }
  async getAccessToken(): Promise<any> {
    const user: any = await this._userManager.getUser();
    if (!!user && !user.expired) {
      localStorage.setItem("access_token", user.access_token);
      return user.access_token;
    } else {
      return null;
    }
  }

  async getUser(): Promise<any> {
    const user = await this._userManager.getUser();
    if (!!user && !user.expired) {
      localStorage.setItem("access_token", user.access_token);
      return user.access_token;
    } else {
      return null;
    }
  }

  getUserInfo = async () => {
    const user = await this._userManager.getUser();
    if (!!user && !user.expired) {
      return user;
    } else {
      return null;
    }
  };

  login = (): void => {
    return this._userManager.signinRedirect();
  };

  async completeLogin(): Promise<any> {
     let result = await this._userManager.signinRedirectCallback();
    try {
      let checkOperator: any = await MerchantQueryService.GetCheckOperatorList();
      localStorage.setItem("checkOperator", JSON.stringify(checkOperator.data));
      return result;
    } catch (err: any) {
      console.error("[STS Error]: cl", err);
    }
  }

  signinSilent = () => {
    this._userManager
      .signinSilent()
      .then((user: any) => {
        console.log("signed in", user);
      })
      .catch((err: any) => {
        console.error("[STS Error]: ss", err);
      });
  };

  logout = () => {
    return this._userManager?.signoutRedirect();
  };

  completeLogout = () => {
    LocalStorageWrapper.clearLocalStorage();
    return this._userManager.signoutRedirectCallback();
  };
}
