import { Grid, Paper, TextField } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import { SCUIContent } from "../../../atomic/organisms";
import { handlerMpOriginCodeList } from "./../../../stores/redux/actions/lookupAction/lookupAction";
import { SCUIButton, SCUIButtonFileUpload, SCUICheckbox, SCUILoading, SCUITextField } from "../../../atomic/atoms";
import { SCUISnackbar, SCUIAlertDialog } from "../../../atomic/molecules";
import Select from "react-select";
import Helper from "../../../utils/helper";
import MerchantCommandService from "../../../services/command/MerchantCommandService";
import customStylesTable from "../../Orders/Const";
import DataTable from "react-data-table-component";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { tr } from "date-fns/locale";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import "./SalesImport.scss";

const excelErrorInfoColumns = [
    { omit: true, selector: (row) => row.id, name: "Id", grow: 0 },
    { selector: (row) => row.sheetName, name: "Sheet Name", grow: 2 },
    { selector: (row) => row.row - 2, name: "Line", grow: 2 },
    { selector: (row) => row.column, name: "Column", grow: 2 },
    {
        selector: (row) => row.message,
        name: "Error",
        cell: (row) => {
            return row.message == null ? <div>Kontrol ediniz</div> : <div>{row.message}</div>
        },
        grow: 2
    },
];

const generalErrorInfoColumns = [
    {
        selector: (row) => row.message,
        name: "Message",
        cell: (row) => {
            return row.message == null ? <div>Unknown Error! Please check it!</div> : <div>{row.message}</div>
        },
        grow: 2
    },
];

class SalesImport extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            mpOrigins: [],
            openMessage: false,
            openDialog: false,
            selectedMpOrigin: {},
            disabledButton: false,
            loading: false,
            selectedFiles: [],
            validationMessage: "",
            status: "",
            showErrorTable: false,
            isGeneralError: false,
            errorData: [],
            checkedFinance: false,
            checkedBIReport: false,
            salesPeriod: null,
            disabledSalesPeriod: true,
            disabledFinanceCheck: true,
            disabledBıReportCheck: true,
            salesPeriodError: false,
            dialogMessage: null,
            errorColumns: []
        };
    }

    componentDidMount() {
        this.getMpOriginCodeList();
        this.getMpOriginsFromIntegration();
        window.addEventListener("beforeunload", this.handleBeforeUnload);
    }

    componentWillUnmount(){
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {
        const message = "Are you sure you want to leave? Unsaved changes will be lost.";
        event.returnValue = message; // Standart çoğu tarayıcı için
        return message; // Eski tarayıcılar için gerekli
      };
    getMpOriginCodeList() {
        this.props.handlerMpOriginCodeList();
    }

    getMpOriginsFromIntegration() {
        this.setState({ loading: true });
        MerchantQueryService.GetMpOriginsFromIntegration({ IsConsignee: true }).then((res) => {
            this.setState({
                mpOrigins: res ?? [],
                loading: false,
            });
        });
    }
    onChange(e) {
        if(e.length <= 0){
            this.setState(this.prepareStateForDefault());
        }
        if (e.length > 1) {
            this.setState({
                selectedFiles: [],
                openMessage: true,
                validationMessage: "Yalnız 1 adet seçebilirsiniz !",
                status: "warning",
            });
        } else {
            if (!Helper.objectIsEmpty(this.state?.selectedMpOrigin)) {
                this.setState({
                    selectedFiles: e,
                    showErrorTable: false,
                    errorData: [],
                    disabledButton: false,
                });
            } else {
                this.setState({
                    // selectedFiles: [],
                    openMessage: true,
                    validationMessage:
                        "Ürün Yüklemesi Yapmak İçin Önce MarketPlace Seçilmelidir!",
                    status: "warning",
                });
            }
        }
    }

    onChangeOriginCode(e) {
        var origin = this.state.mpOrigins.filter(x => x.originCode == e.value)[0];
        if (!origin.salesPeriod) {
            this.setState(
                {
                    disabledSalesPeriod: true,
                    salesPeriod: null
                },
                () => { })
        }
        else {
            this.setState(
                {
                    disabledSalesPeriod: false,
                    salesPeriod: null
                },
                () => { })
        }
        if (origin.transferToAll) {
            this.setState(
                {
                    disabledButton: this.state.selectedFiles.length > 0 ? false : true,
                    selectedMpOrigin: e,
                    disabledBıReportCheck: true,
                    disabledFinanceCheck: true,
                    checkedBIReport: true,
                    checkedFinance: true
                },
                () => { })
        }
        else {
            this.setState(
                {
                    disabledButton: this.state.selectedFiles.length > 0 ? false : true,
                    selectedMpOrigin: e,
                    disabledBıReportCheck: false,
                    disabledFinanceCheck: false,
                    checkedBIReport: false,
                    checkedFinance: false
                },
                () => { })
        }
    }
    mapGeneralEror(res) {
        if (res?.data?.data?.generalErrorMessages != null) {
            var errors = res?.data?.data?.generalErrorMessages.map(message => ({ message }));
            errors.unshift({ message: "One or more errors occurred! Exceptions might appear as shown below!" });
            return errors;
        }
        if (res?.data?.message != null) {
            return [{ message: res?.data?.message }];
        }
        return [{ message: "An error occured! Please check the server logs." }];
    }
    handleClose() {
        this.setState({ openDialog: false });
    }
    
    handleDialogAction(isCancel) {
        if (isCancel) {
            this.setState(this.prepareStateForDefault());
            return;
        }

        this.setState({ disabledButton: true, loading: true })

        var excelImportModel = {
            OriginCode: this.state?.selectedMpOrigin?.value,
            FileType: "Sales",
            PeriodDate: this.state.salesPeriod,
            TransferType: this.getTransferType(),
            IsApprovedFromDialog: !isCancel,
            FileHash: this.state.dialogMessage.transferredFileHash
        };
        const data = Helper.jsonToFormData(excelImportModel);
        for (let index = 0; index < this.state.selectedFiles.length; index++) {
            data.append("File", this.state.selectedFiles[index]);
        }
        try {
            MerchantCommandService.ImportSalesExcel(data)
                .then((res) => {
                    this.handlePushDataResponse(res, false);
                })
                .catch((res) => {
                    this.handlePushDataResponse(res, true);
                });
        }
        catch (error) {
            console.log(error)
        }

    }
    handlePushDataResponse(res, isFromCatch) {
        if (isFromCatch) {
            this.handleCatchResponse(res);
            return;
        }
        this.handleSuccessResponse(res);
    }

    handleCatchResponse(res) {
        var isGeneralError = res?.data?.data?.excelImportValidationErrors == null && (res?.data?.data?.generalErrorMessages != null || res.data?.message != null);
        if (isGeneralError) {
            this.setState(this.prepareStateForGeneralError(res));
            return;
        }
        this.setState(this.prepareStateForExcelValidationError(res))
    }
    handleSuccessResponse(res) {
        var isInformation = res?.data?.importOrderInformation != null;
        if (isInformation) {
            this.setState(this.prepareStateForInformation(res));
            return;
        }

        this.setState({
            selectedFiles: [],
            openMessage: true,
            validationMessage: "Excel veri yükleme başarıyla tamamlandı",
            status: "success",
            disabledButton: true,
            loading: false,
            dialogMessage: null
        });
    }
    prepareStateForDefault(){
        return {
            openDialog: false,
            openMessage: false,
            validationMessage: "",
            status:"",
            loading: false,
            dialogMessage: null,
            showErrorTable:false,
            disabledButton: false,
            selectedFiles:[]
        };
    }
    prepareStateForInformation(res) {
        return {
            openDialog: true,
            openMessage: false,
            validationMessage: "",
            status: "success",
            loading: false,
            dialogMessage: res?.data?.importOrderInformation,
            showErrorTable: false
        };
    }
    prepareStateForGeneralError(res) {
        this.setState({
            isGeneralError: true,
            showErrorTable: true,
            errorData: this.mapGeneralEror(res),
            openMessage: false,
            validationMessage: "",
            status: "danger",
            disabledButton: false,
            loading: false,
            errorColumns: generalErrorInfoColumns
        });
    }
    prepareStateForExcelValidationError(res) {
        this.setState({
            isGeneralError: false,
            showErrorTable: true,
            errorData: res?.data?.data?.excelImportValidationErrors,
            openMessage: false,
            validationMessage: "",
            status: "danger",
            disabledButton: false,
            loading: false,
            errorColumns: excelErrorInfoColumns
        });
    }
    pushData() {
        if (this.state.selectedFiles.length > 0) {
            if (this.state.checkedFinance == true || this.state.checkedBIReport == true) {
                var origin = this.state.mpOrigins.filter(x => x.originCode == this.state?.selectedMpOrigin?.value)[0];
                if (origin.salesPeriod == true && this.state.salesPeriod == null && this.state.checkedFinance == true) {
                    this.setState({
                        openMessage: true,
                        validationMessage: `${origin.name} için dönem seçimi zorunlu!`,
                        status: "danger",
                        salesPeriodError: true
                    });
                }
                else {
                    this.setState({ disabledButton: true, loading: true, showErrorTable: false })


                    var excelImportModel = {
                        OriginCode: this.state?.selectedMpOrigin?.value,
                        FileType: "Sales",
                        PeriodDate: this.state.salesPeriod,
                        TransferType: this.getTransferType(),
                        IsApprovedFromDialog: false,
                        FileHash: null
                    };

                    const data = Helper.jsonToFormData(excelImportModel);
                    for (let index = 0; index < this.state.selectedFiles.length; index++) {
                        data.append("File", this.state.selectedFiles[index]);
                    }

                    try {
                        if (!this.preValidationBeforeSalesImport()) {
                            return;
                        }
                        MerchantCommandService.ImportSalesExcel(data)
                            .then((res) => {
                                this.handlePushDataResponse(res, false);
                            })
                            .catch((res) => {
                                this.handlePushDataResponse(res, true);
                            });
                    }
                    catch (error) {
                        console.log(error)
                    }
                }

            }
            else {
                this.setState({
                    openMessage: true,
                    validationMessage: "En az bir aktarım yöntemi seçmelisiniz !",
                    status: "danger",
                });
            }


        } else {
            this.setState({
                openMessage: true,
                validationMessage: "Herhangi bir dosya yüklenmedi!",
                status: "warning",
            });
        }
    }
    getTransferType() {
        var transferredTo = [];
        if (this.state.checkedFinance)
            transferredTo.push(1)
        if (this.state.checkedBIReport)
            transferredTo.push(2)
        return transferredTo;
    }
    preValidationBeforeSalesImport() {
        if (this.state.dialogMessage != null) {
            this.setState({ openDialog: true })
            return false;
        }
        return true;
    }

    getDialogContent() {
        var dialogMessage = this.state.dialogMessage;
        if (dialogMessage == null) {
            return <>
                <p>Informatin data not found! Please contact with your administrator!</p>
            </>
        }
        return <>
            <p><b>Satılan Barcode Adedi-:</b> {dialogMessage.salesItemBarcodeCount}</p>
            <p><b>Satış Adedi------------:</b> {dialogMessage.salesItemsCount}</p>
            <p><b>Toplam Satış Tutarı----:</b> {dialogMessage.totalSalesPrice}</p><br />
            <p><b>İade Barcode Adedi----:</b> {dialogMessage.returnItemBarcodeCount}</p>
            <p><b>İade Sayısı-------------:</b> {dialogMessage.returnItemCount}</p>
            <p><b>Toplam İade Tutarı-----:</b> {dialogMessage.totalReturnPrice}</p><br />
            <p><b>Satır Sayısı------------:</b> {dialogMessage.rowCount}</p>
        </>;
    }
    render() {
        return (
            <SCUIContent>
                <Grid container direction="column">
                    <Paper sx={{ padding: "2rem", margin: "2rem", width: "100%" }}>

                        <Grid container spacing={4}>
                            <Grid item xs={2} mt={3}  >
                                <Select
                                    styles={customStyles}
                                    placeholder={"Origin Code"}
                                    value={this.state.selectedMpOrigin}
                                    onChange={(e) =>
                                        this.onChangeOriginCode(e)
                                    }
                                    options={this.props?.mpOrderOriginCodeList.filter(item => { return this.state.mpOrigins.some(item2 => item2.originCode == item.value) })}
                                />
                            </Grid>

                            <Grid item xs={1} mt={3} className="containers" >
                                <div className="smallText"> BI Report</div>
                                <SCUICheckbox
                                    checked={this.state.checkedBIReport}
                                    defaultChecked={false}
                                    disabled={this.state.disabledBıReportCheck}
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                checkedBIReport: e.target.checked,
                                                disabledSalesPeriod: true,
                                                checkedFinance: e.target.checked ?
                                                    this.state.mpOrigins.filter(x => x.originCode == this.state.selectedMpOrigin.value)[0].transferToAll ?
                                                        false
                                                        : null

                                                    : null,
                                                salesPeriod: null,
                                            },
                                            () => { }
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} mt={3} className="containers">
                                <div className="smallText"> Finance</div>
                                <SCUICheckbox
                                    checked={this.state.checkedFinance}
                                    defaultChecked={false}
                                    disabled={this.state.disabledFinanceCheck}
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                checkedFinance: e.target.checked,
                                                disabledSalesPeriod: e.target.checked ?
                                                    this.state.mpOrigins.filter(x => x.originCode == this.state.selectedMpOrigin.value)[0].salesPeriod ?
                                                        false
                                                        : true
                                                    : true,
                                                checkedBIReport: e.target.checked ?
                                                    this.state.mpOrigins.filter(x => x.originCode == this.state.selectedMpOrigin.value)[0].transferToAll ?
                                                        false
                                                        : null

                                                    : null
                                            },
                                            () => { }
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3} mt={3} className="containers">
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                                    <DatePicker
                                        renderInput={(props) => (
                                            <TextField {...props} style={{ width: "190px" }} />
                                        )}
                                        label={"Dönem"}
                                        inputFormat="dd/MM/yyyy"
                                        value={this.state.salesPeriod}
                                        disabled={this.state.disabledSalesPeriod}
                                        className={!this.state.salesPeriodError ? "" : 'red-border'}
                                        onChange={(event) => {
                                            event.setHours(0, 0, 0, 0);
                                            this.setState({
                                                // disabledButton: false,
                                                salesPeriod: event.toLocaleDateString("en-US"),
                                                salesPeriodError: false
                                            },
                                            );
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={10} mt={3}>
                                <SCUIButtonFileUpload
                                    onChange={(e) => this.onChange(e)}
                                    selectedFiles={this.state.selectedFiles}
                                />
                            </Grid>
                            <Grid item xs={2} mt={3}>

                                <SCUIButton
                                    text={"Satış Dosyası Yükle"}
                                    type="primary"
                                    disabled={
                                        !Helper.objectIsEmpty(this.state?.selectedMpOrigin) && !this.state.disabledButton
                                            ? false
                                            : true
                                    }
                                    sizeType="fixed"
                                    size={"m"}
                                    onClick={() => this.pushData()}
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{ width: "100%", margin: "1rem", padding: "1rem" }}>
                        <div>
                            <SCUIAlertDialog
                                open={this.state.openDialog}
                                status="success"
                                iconShow={true}
                                showBtnCancel={true}
                                showBtnConfirm={true}
                                btnConfirmText={"Onayla"}
                                btnCancelText={"Vazgeç"}
                                onClose={() => { this.handleClose() }}
                                onClickAgreed={() => { this.handleDialogAction(false) }}
                                onClickNotAgreed={() => { this.handleDialogAction(true) }}
                                content={this.getDialogContent()}
                            />
                        </div>
                        <div style={{ height: 2550, width: "100%" }}>
                            {this.state.showErrorTable ? (
                                <DataTable
                                    title={"Kayıt Hataları"}
                                    columns={this.state.errorColumns}
                                    data={this.state.errorData}
                                    highlightOnHover
                                    customStyles={customStylesTable}
                                    paginationPerPage={50}
                                    pagination={true}
                                />
                            ) : this.state.loading ? <SCUILoading /> : null}
                        </div>
                    </Paper>
                </Grid>

                <SCUISnackbar
                    messageText={this.state.validationMessage}
                    status={this.state.status}
                    show={this.state.openMessage}
                    onClose={() => this.setState({ openMessage: false })}
                    autoHideDuration={3000}
                />
            </SCUIContent>
        );
    }
}

const customStyles = {
    zIndex: "inherit",
    control: (base) => ({
        ...base,
        height: 55,
        minHeight: 35,
        zIndex: 9999,
        flex: 10,
    }),
    menuPortal: (base) => ({
        ...base,

        zIndex: 9999,
    }),
    container: (provided) => ({
        ...provided,
        width: "250px",
        height: "50px", // Ana container'ın genişliğini belirleyebilirsiniz
    }),
};

const mapDispatchToProps = (dispatch) => {
    return {
        handlerMpOriginCodeList: () => handlerMpOriginCodeList(dispatch, true),
    };
};

const mapStateToProps = ({ lookups }) => ({
    mpOrderOriginCodeList: lookups.mpOrderOriginCodeList,
});
export default connect(mapStateToProps, mapDispatchToProps)(SalesImport);